var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticStyle: {
        display: "grid",
        "justify-content": "center",
        "align-content": "center",
        margin: "auto",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "ma-auto pa-0", attrs: { flat: "" } },
        [
          _c(
            "v-alert",
            {
              attrs: {
                outlined: "",
                prominent: "",
                border: "top",
                color: "warning",
              },
            },
            [
              _c(
                "div",
                { staticClass: "alert-container" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "alert-icon mx-auto mt-2 mb-3",
                      attrs: { color: "warning" },
                    },
                    [_vm._v(" mdi-alert-outline ")]
                  ),
                  _c("div", { staticClass: "text-h6" }, [
                    _vm._v(" Temporarily Down for Maintenance "),
                  ]),
                  _c("v-divider", {
                    staticClass: "my-4 mx-auto warning",
                    staticStyle: {
                      opacity: "0.22",
                      width: "100%",
                      "max-width": "80%",
                    },
                  }),
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "grow" }, [
                        _vm._v(
                          " Service is unavailable at this time due to scheduled website maintenance."
                        ),
                        _c("br"),
                        _vm._v(" Please check back later."),
                        _c("br"),
                        _c("br"),
                        _vm._v(" We apologize for the inconvenience. "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }